import React from 'react';

import Classes9to122024  from 'views/Classes9to122024';


const ClassAssembly11CPage = () => {
    return <Classes9to122024 />;
  };
  
  export default ClassAssembly11CPage;

  