import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
// import p1 from 'assests/Photos/Clubs/ScienceClub/2024/GeneralCCA/1.jpg';
// import p2 from 'assests/Photos/Clubs/ScienceClub/2024/GeneralCCA/2.jpg';
// import p3 from 'assests/Photos/Clubs/ScienceClub/2024/GeneralCCA/3.jpg';
// import p4 from 'assests/Photos/Clubs/ScienceClub/2024/GeneralCCA/4.jpg';
// import p5 from 'assests/Photos/Clubs/ScienceClub/2024/GeneralCCA/5.jpg';



import { Typography } from '@mui/material';
import Container from 'components/Container';
import { Sidebar2023 } from 'views/Literaryclub2023/components';
import { LiterarySidebar } from 'views/Literaryclub2023/components';
import Sidebar2024 from '../ScienceClub/Sidebar2024';
import Scienceclubsidebar2023 from '../ScienceClub/Scienceclubsidebar2023';
import ScienceClubSideBar from '../ScienceClubSideBar';

const Classes9to122024 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const [config, setConfig] = useState({ base_image_url: '' });
    useEffect(() => {
        fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
            .then(response => response.json())
            .then(data => setConfig(data))
            .catch(error => console.error('Error fetching config:', error));
    }, []);

    const p1 = `${config.base_image_url}/home/events-activities/association/ScienceClub/9-12/1.webp`;
    const p2 = `${config.base_image_url}/home/events-activities/association/ScienceClub/9-12/2.webp`;
    const p3 = `${config.base_image_url}/home/events-activities/association/ScienceClub/9-12/3.webp`;
    const p4 = `${config.base_image_url}/home/events-activities/association/ScienceClub/9-12/4.webp`;
    const p5 = `${config.base_image_url}/home/events-activities/association/ScienceClub/9-12/5.webp`;
    const p6 = `${config.base_image_url}/home/events-activities/association/ScienceClub/9-12/6.webp`;


    const photos = [
        {
            src: p1,
            source: p1,
            rows: 2.2,
            cols: 1,
        },

        {
            src: p3,
            source: p3,
            rows: 2.2,
            cols: 1,
        },
        {
            src: p4,
            source: p4,
            rows: 2,
            cols: 1,
        },

        {
            src: p6,
            source: p6,
            rows: 2,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 1.3,
            cols: 2,
        },
        {
            src: p5,
            source: p5,
            rows: 1.3,
            cols: 2,
        }
    ];

    return (
        <Main>

            <Container>

                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box paddingX={{ xs: 0, sm: 4, md: 6 }}>
                                <Typography variant='h5' align='center' >
                                From The Ground Up: The How and Why of Structures

                                </Typography>
                                <br />
                                {/* <Typography variant='h7' align='justify' >
                                Class 3: Vegan power,
                                Class 4: Plant Power Saves the World,
                                Class 5: Significance of spices in Indian Cuisine  
                                </Typography> */}
                                <Typography
                                    fontSize="x-small"
                                > <br />
                                    Classes: 9 to 12    Date:  27 th September 2024

                                </Typography>

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                    In a captivating fusion of Science, Engineering and creativity, students of Classes 9 to 12, came together
                                    to explore the complexities behind some of the world’s most iconic structures. The event, “From The
                                    Ground Up: The How and Why of Structures”, organized by the Senior Science Association on 27 th
                                    September 2024, was a true showcase of teamwork, research, and presentation skills.
                                    <br/>
                                    Around 8 teams, from different houses, went head-to-head in delivering research-based presentations
                                    using creative visual aids and practical demonstrations, the teams were able to break down complex
                                    scientific concepts into engaging formats. From the acoustics behind the design of the Allianz Arena in
                                    Munich to the structural integrity of the Chenab Rail Bridge, the students not only demonstrated
                                    technical understanding but also had the ability to captivate the audience.
                                    <br />
                                        To sum it up, the event wasn’t about competition; it was about instilling a deeper appreciation for the
                                        science that shapes the built environment around us. The participants’ curiosity and enthusiasm were
                                        appreciable and the success of the event was a testament to their hard work and dedication.
                                    <br />
                                </Typography>
                            </Box>
                            <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                “You can dream, create, design and build the most wonderful place in the world.”
                                <br />
                            </Typography>

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 200 : 100}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={4}>

                        <Box marginBottom={4}>
                            <Sidebar2024 />
                        </Box>
                        <Box marginBottom={4}>
                            <ScienceClubSideBar />

                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Main>


    );
};

export default Classes9to122024;